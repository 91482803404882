import { Button, Tooltip } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { CreateFormModalButton } from 'components/create-form-modal'
import { StartCheckinButtonVm } from './start-checkin-button-vm'
import { store } from 'store/index'
import { User } from 'store/modules/users'
import { useStore } from 'store/context'

export type StartCheckinButton = {
  className?: string
  source: string
  user: User
  variant?: React.ComponentProps<typeof Button>['variant']
}

export const StartCheckinButton: React.VFC<StartCheckinButton> = (props) => {
  const { className, source, user, variant = 'default' } = props

  const { currentUser } = useStore()

  const vm = React.useMemo(() => {
    return new StartCheckinButtonVm()
  }, [])

  if (!currentUser) return null

  if (user.isCurrentUser) {
    return (
      <CreateFormModalButton
        label="Start Check-in"
        source={source}
        tabs={['checkin']}
        user={currentUser}
        variant={variant}
      />
    )
  }

  const tooltipContent = vm.tooltipContent(user)

  return (
    <>
      {tooltipContent ? (
        <Tooltip content={tooltipContent}>
          <Button
            className={cn('bg-theme-40 opacity-50 text-white', className)}
            disabled
            variant={variant}
          >
            Start Check-in
          </Button>
        </Tooltip>
      ) : (
        <Button
          className={className}
          disabled={store.checkins.loading}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault()
            vm.onClick(user.id, currentUser.id, source)
          }}
          variant={variant}
        >
          Start Check-in
        </Button>
      )}
    </>
  )
}
