import { avatarGroupProps } from '../../utils/user-helpers'
import { Checkin } from 'store/modules/checkins'
import { store } from 'store/index'

export class CheckinProgressBarVm {
  constructor(private checkin: Checkin) {}

  get actionText() {
    const actionWord = this.isAuthor ? 'your' : `${this.authorName}'s`

    switch (this.checkin.status) {
      case 'not_started':
      case 'in_progress':
        return `${
          actionWord.charAt(0).toUpperCase() + actionWord.slice(1)
        } self Check-in`
      case 'pending_assessor':
        return this.isAuthor || !this.isAssessor
          ? `Waiting to review ${actionWord} Check-in`
          : `Start ${actionWord} Check-in review`
      case 'assessor_in_progress':
        return this.isAuthor || !this.isAssessor
          ? `Reviewing ${actionWord} Check-in`
          : `Review ${actionWord} Check-in`
      case 'pending_review':
        return this.isAssessor || this.isAuthor
          ? `Start ${actionWord} joint Check-in`
          : `Waiting for ${actionWord} joint Check-in`
      case 'review_in_progress':
        return this.isAssessor || this.isAuthor
          ? `Continue ${actionWord} joint Check-in`
          : `Reviewing ${actionWord} joint Check-in`
      case 'finalised':
        return `Completed ${actionWord} Check-in`
    }
  }

  get minimalActionText() {
    switch (this.checkin.status) {
      case 'not_started':
      case 'in_progress':
        return this.isAuthor ? 'With you' : `With ${this.authorName}`
      case 'pending_assessor':
      case 'assessor_in_progress':
        return this.isAssessor ? 'With you' : `With ${this.assessorName}`
      case 'pending_review':
      case 'review_in_progress':
        return 'Joint review'
      case 'finalised':
        return 'Completed'
    }
  }

  get avatars() {
    const users = []

    if (store.currentUser && (this.isAssessor || this.isAuthor)) {
      users.push(store.currentUser)
    }

    if (!this.isAuthor) users.push(this.checkin.author)

    if (this.checkin.assessor && !this.isAssessor) {
      users.push(this.checkin.assessor)
    }

    return avatarGroupProps(users)
  }

  get isWithUser() {
    switch (this.checkin.status) {
      case 'not_started':
      case 'in_progress':
        return this.isAuthor
      case 'pending_assessor':
      case 'assessor_in_progress':
        return this.isAssessor
      case 'pending_review':
      case 'review_in_progress':
        return this.isAssessor || this.isAuthor
      default:
        return false
    }
  }

  get statusPercentage() {
    switch (this.checkin.status) {
      case 'not_started':
      case 'in_progress':
        return 25
      case 'pending_assessor':
        return 33
      case 'assessor_in_progress':
        return 50
      case 'pending_review':
        return 66
      case 'review_in_progress':
        return 80
      case 'finalised':
        return 100
    }
  }

  get userText() {
    switch (this.checkin.status) {
      case 'not_started':
      case 'in_progress':
        return this.isAuthor ? 'You' : this.authorName
      case 'pending_assessor':
      case 'assessor_in_progress':
        return this.isAssessor ? 'You' : this.assessorName
      case 'pending_review':
      case 'review_in_progress':
        return this.isAssessor || this.isAuthor
          ? 'You'
          : `${this.authorName} & ${this.assessorName}`
      default:
        return null
    }
  }

  private get assessorName() {
    return this.checkin.assessor ? this.checkin.assessor.fname : 'Assessor'
  }

  private get authorName() {
    return this.checkin.author.fname
  }

  private get isAssessor() {
    return this.checkin.assessor?.id === store.currentUser?.id
  }

  private get isAuthor() {
    return this.checkin.author.id === store.currentUser?.id
  }
}
