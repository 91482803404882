import * as React from 'react'
import cn from 'classnames'
import { Checkin } from 'store/modules/checkins'
import { CheckinProgressBarVm } from './checkin-progress-bar-vm'
import styles from './styles.module.scss'

type CheckinProgressBarProps = {
  checkin: Checkin
  minimal?: boolean
}

export const CheckinProgressBar: React.VFC<CheckinProgressBarProps> = (
  props
) => {
  const { checkin, minimal = false } = props

  const vm = new CheckinProgressBarVm(checkin)

  return (
    <div className="flex flex-col gap-1.5 w-full">
      <div
        className={cn(
          'text-sm truncate',
          vm.isWithUser ? 'text-gray-600' : 'text-gray-400'
        )}
      >
        {!minimal && vm.userText && <span>{vm.userText} · </span>}
        {minimal ? vm.minimalActionText : vm.actionText}
      </div>
      <div className="flex gap-1.5 items-center">
        <progress
          className={cn(
            'h-1 rounded w-full min-w-[150px]',
            styles[checkin.status],
            styles['checkin-progress-bar']
          )}
          max="100"
          value={vm.statusPercentage}
        >
          {vm.statusPercentage}%
        </progress>
      </div>
    </div>
  )
}
