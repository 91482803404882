import { store } from 'store/index'
import { User } from 'store/modules/users'

export class StartCheckinButtonVm {
  onClick = async (userId: string, currentUserId: string, source?: string) => {
    await store.checkins.create(
      {
        assessor: currentUserId,
        author: userId,
        initiated_by: 'assessor',
      },
      { source }
    )
  }

  tooltipContent(user: User) {
    if (!user.position) {
      return `${user.fname} requires a position to start a Check-in`
    }

    if (user.position?.team?.isDraft) {
      return `${user.fname} is assigned to a draft team. Please publish the team before starting a Check-in for ${user.fname}.`
    }
  }
}
